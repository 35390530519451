const previewImg = "https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/preview_img.png";

export const generateRandomStringWithTimestamp = () => {
  const length = 30;
  const characters = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'; // 去除易混淆字符
  const charactersLength = characters.length;
  let randomString = '';

  for (let i = 0; i < length; i++) {
    randomString += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  const timestamp = Date.now(); // 获取当前时间的时间戳（以毫秒为单位）
  return randomString + timestamp;

};


export const getProductImage = (product) =>{
  if (product){
    if (product.alyImage){
      return product.alyImage;
    }
    if (product.image){
      return product.image;
    }
    return previewImg;
  }
  return previewImg;
};


export const getProductImages = (product) =>{
  if (product){
    if (product.alyImages){
      return product.alyImages;
    }
    if (product.images){
      return product.images;
    }
    return previewImg;
  }
  return previewImg;
};
