import {Modal} from "antd";
import React from "react";
import styles from "./index.module.scss";
import {FailIcon} from "@/config/config";
import {CommonNormalBtn, CommonPrimaryBtn} from "@/components/CommonBtn";
import {useRouter} from "next/router";
import useDispatchAction from "@/hooks/useDisptachAction";
import {setUserInfo} from "@/redux/actions/home";
import Link from "next/link";
import host from "@/utils/host";


const LogoutModal = ({show, onCancel}) =>{
  const router = useRouter();
  const dispatchAction = useDispatchAction({ setUserInfo });
  return <Modal title={null} centered footer={null} width={618} open={show} onCancel={()=>{
    onCancel();
  }}>
    <div>
      <div className={styles.create_link_page}>
        <img src={FailIcon} className={"w-[60px] h-[60px]"} />
        <div className={"mt-[28px] text_222 text-[14px] text-center"}>
          You'll need to <span className={"font-bold "}>log in again</span> the next time you use the platform after logging out.
          Do you confirm to log out the platform?
        </div>

        <div className={"flex items-center gap-[48px] w-full mt-[28px]"}>
          <CommonNormalBtn onClick={()=>{
            localStorage.removeItem("userInfo");
            localStorage.removeItem("token");
            dispatchAction.setUserInfo(null);
            router.push("/");
            onCancel();
          }}>Yes</CommonNormalBtn>
          <CommonPrimaryBtn onClick={()=>{
            onCancel();
          }}>No</CommonPrimaryBtn>
        </div>

      </div>
    </div>
  </Modal>;

};

export default LogoutModal;
