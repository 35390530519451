import { useRouter } from "next/router";
import { Icon } from "@iconify/react";
import styles from './index.module.scss';
const EmptyMobile = ({ result, routerAddress }) => {
  const router = useRouter();
  const refresh = () => {
    if (routerAddress) {
      router.push(routerAddress);
    } else {
      router.reload();
    }
  };

  return (
    <div className={styles.emptyMobile}>
      <img className={styles.emptyImg} src='/static/image20.png' ></img>
      <div className={styles.emptyMsg}>
        {result}
      </div>
      <div onClick={refresh} className={styles.emptyBtn}>
        <Icon icon='icon-park-solid:good-two' className={styles.emptyBtnIcon} />
        <span className={styles.emptyBtnText} >Recommended For You</span>
      </div>
    </div>
  );
};
export default EmptyMobile;