import {ToHomePageIcon} from "@/config/config";
import {useRouter} from "next/router";
import Link from "next/link";
import styles from "./index.module.scss";


const noIconPath = ["/", "/helpCenter"];

const ToHomePageLink = () =>{

  const router = useRouter();

  return <>
    {
      !noIconPath.includes(router.pathname) &&  <Link href={"/"}>
        <img src={ToHomePageIcon} className={`${styles.css} cursor-pointer w-[40px] h-[40px] fixed z-[666] top-[144px] left-[148px]`} />
      </Link>
    }
  </>;
};

export default ToHomePageLink;
