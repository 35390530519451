import React, { useCallback, useRef, useState, useMemo, useEffect } from 'react';
import styles from './index.module.scss';
import debounce from 'lodash.debounce';
import { Input, Select } from "antd";
import { CloseCircleFilled } from "@ant-design/icons";
import {HistoryIcon} from "@/config/config";
const { Option } = Select;
const SearchDropDown = ({ onSearch, show, hover, noHover, onBlur }) => {

  const [history, setHistory] = useState([]);

  useEffect(() => {
    const list = localStorage.getItem("history");
    if (list) {
      setHistory(JSON.parse(list));
    }
  }, [show]);

  const onRemove = (e, item) => {
    e.stopPropagation();
    const newHistory = [];
    for (let i = 0; i < history.length; i++) {
      if (history[i].name === item.name) continue;
      newHistory.push(history[i]);
    }
    setHistory(newHistory);
    localStorage.setItem("history", JSON.stringify(newHistory));
  };

  if (!history || history.length <= 0) return <div></div>;

  const renderTag = (item) =>{
    if (Number(item.type) === 1){
      return <div className={"px-1 bg-[#FFF3ED] text-[#FF6E1F] rounded mr-3 shrink-0 "}>
        Product Link
      </div>;
    }
    return <div className={"px-1 bg-[#FFF3ED] text-[#FF6E1F] rounded mr-3 shrink-0"}>
      Product Name
    </div>;
  };

  return (
    <div className={styles.SearchDropDown}>
      <div onMouseEnter={hover} onMouseLeave={noHover} className={styles.dropDown}>
        {
          history.map((item, index) => {
            return <div key={index} onClick={(e) => {
              e.stopPropagation();
              onSearch(e, item);
            }} className={`${styles.dropDownItem} flex items-center`}>
              <div className={"flex items-center"}>
                <img src={"/static/historyTime.png"} className={"object-cover object-center mr-3 shrink-0"} style={{ withdth: 16, height: 16}}></img>
                <div className={"flex items-center"}>{renderTag(item)} {item.name}</div>
              </div>
              <div onClick={(e) => onRemove(e, item)}><CloseCircleFilled /></div>
            </div>;
          })
        }
      </div>
    </div>
  );
};

export default SearchDropDown;
