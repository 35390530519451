import CommonLoading from "@/components/CommonLoading";


export const CommonPrimaryBtn = ({children, loading, className, style, onClick}) =>{
  const click =  () =>{
    if (loading) return;
    onClick?.();
  };

  return <div onClick={click} style={{
    opacity: loading ? 0.7 : 1,
    ...style
  }} className={`flex rounded items-center text-white cursor-pointer bg-linear justify-center h-[36px] w-full text-[14px] font-[700] ${className}`}>
    {
      loading && <CommonLoading className={"w-[20px] h-[20px]"}></CommonLoading>
    }
    {
      children
    }
  </div>;
};


export const CommonNormalBtn = ({children, loading, className, style, onClick}) =>{
  const click =  () =>{
    if (loading) return;
    onClick();
  };

  return <div onClick={click} style={style} className={`flex rounded border border-[#222222] items-center text_222 cursor-pointer justify-center h-[36px] w-full text-[14px] font-[400] ${className}`}>
    {
      loading ? <CommonLoading className={"w-[20px] h-[20px]"}></CommonLoading> : children
    }
  </div>;
};
