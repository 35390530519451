import styles from './index.module.scss';
const MoreMobileModal = ({ isVisible, content, closePopup }) => {
  return (
    isVisible && <div className={styles.moreModalOverlay} onClick={(e) => {
      e.preventDefault();
      closePopup();
    }}>
      <div className={styles.moreModal}>{content}
      </div>
    </div>
  );
};
export default MoreMobileModal;