import {SET_USER_INFO, SET_USER_PERMISSION, SET_WALLET_INFO} from '@/constants/ActionTypes';

const initialState = {
  userInfo: {},
  permission:false,
};

export const userInfo = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_USER_INFO: {
      return {
        ...state,
        userInfo:payload
      };
    }
    default:
      return state;
  }
};
export const permission = (state = initialState, { type, payload }) => {
  console.log(`set SET_USER_PERMISSION ${payload}`);
  switch (type) {
    case SET_USER_PERMISSION: {
      return {
        ...state,
        permission:payload
      };
    }
    default:
      return state;
  }
};
