import Link from 'next/link';
import getConfig from 'next/config';
import { useRouter } from "next/router";
import host from "@/utils/host";
import { APIGetCheckV2Permissions, APIGetUserInfo } from "@/api";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import useDispatchAction from "@/hooks/useDisptachAction";
import { setUserInfo } from "@/redux/actions/home";
import { Avatar, Input, message, Modal, Popover, Select } from "antd";
import { getQueryString, isMobile } from "@/utils/action";
import LoginModal from "@/components/LoginModal";
import { LoadingOutlined } from "@ant-design/icons";
import NotAuthModal from "@/components/NotAuthModal/index";
import {ArrowDownIcon, ClearIcon, SearchIcon} from "@/config/config";
import SubNotAuthMobile from '@/components/SubNotAuthMobile';
import LogoutModal from "@/components/LogoutModal";
import SearchDropDown from "@/components/SearchDropDown";
import MoreMobileModal from '@/components/MoreMobileModal';
import AuthorizationModal from "@/components/AuthorizationModal";
import { useRequest } from "ahooks";
// import Popup from '@/components-mobile/Popup';
const { Option } = Select;
const { publicRuntimeConfig: { staticFolder } } = getConfig();
const Header = () => {
  const router = useRouter();
  const userInfo = useSelector(state => state.home.userInfo.userInfo);

  const [mobile, setMobile] = useState();
  const [openConnectModal, setOpenConnectModal] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const [goPageModal, setGoPageModal] = useState(false);
  const [notAuth, setNotAuth] = useState(false);
  const [notAuthMobile, setNotAuthMobile] = useState(false);

  const [showNeedAuthModal, setShowNeedAuthModal] = useState(false);
  const [userPermissions, setUserPermissions] = useState(-1); // -1:等待接口返回， 1:已经授权  0:未授权


  const dispatchAction = useDispatchAction({ setUserInfo });
  const getUserInfo = () => {
    const token = localStorage.getItem("token");
    if (token) {
      APIGetUserInfo().then(async resp => {
        if (resp.data.user) {
          const user = resp.data.user;
          await checkPermission();
          dispatchAction.setUserInfo(user);
          localStorage.setItem("user", JSON.stringify(user));
        }
      });
    }
  };
  const goSearchPage = () => {
    router.push("/search-page-mobile/");
  };

  const goProfile = () => {
    if (!userPermissions) {
      setNotAuthMobile(true);
      return;
    }
    setGoPageModal(false);
    router.push('/profile-mobile');
  };

  const goShowcase = () => {
    if (!userPermissions) {
      setNotAuthMobile(true);
      return;
    }
    setGoPageModal(false);
    router.push(mobile ? '/showcase-mobile' : '/person-center?tabKey=2');
  };


  const checkPermission = async () => {
    await new Promise((resolve) => {
      APIGetCheckV2Permissions().then(resp => {
        if (resp.data) {
          setUserPermissions(resp.data?.permissions ? 1 : 0);
          resolve();
        }
      }).finally(() => {
        resolve();
      });
    });
  };

  useEffect(() => {
    // 初始化获取用户信息和权限
    const token = localStorage.getItem("token");
    if (token) {
      checkPermission().then();
    }

    // 监听页面可见性变化（标签页切换）
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        checkPermission(); // 页面切换回来时调用 checkPermission
      }
    };

    // 绑定事件监听
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // 清理事件监听
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const gotoShowcase = (tabKey) => {
    if (userPermissions === 1) {
      router.push(`/person-center?tabKey=${tabKey}`);
    } else {
      setShowNeedAuthModal(true);
    }
  };


  useEffect(() => {
    setMobile(isMobile());
    const tokenStr = getQueryString("token");
    const errMsg = getQueryString("errMsg");
    if (tokenStr) {
      localStorage.setItem("token", tokenStr);
      router.push("/");
    }
    if (errMsg) {
      setNotAuth(true);
    }
    getUserInfo();
  }, []);
  const content = (
    <div className={"pop_content"}>
      {mobile ? <>
        <div onClick={() => { goProfile(); }}>My Profile</div>
        <Link href={"/collection-mobile"}><div onClick={(e) => e.stopPropagation()}>My Collection</div></Link>
        <div onClick={() => { goShowcase(); }}>My Showcase</div>
      </> :
        <>
          <Link href={"/person-center?tabKey=3"}> <div>My Collection</div> </Link>
          <div onClick={() => gotoShowcase(2)}>My Showcase</div>
        </>}
      <div onClick={(e) => {
        setLogoutModal(true);
        // localStorage.removeItem("userInfo");
        // localStorage.removeItem("token");
        // dispatchAction.setUserInfo(null);
        // setPopoverVisible(false);
        // router.push("/");
      }}>Logout</div>
    </div>
  );



  return (
    <div id='header_bar' className='header_container'>
      <NotAuthModal show={notAuth} mobile={mobile} onCancel={() => setNotAuth(false)} />
      <AuthorizationModal show={showNeedAuthModal} onCancel={() => setShowNeedAuthModal(false)}></AuthorizationModal>

      <Link href='/'>
        <div className={"flex items-center"}>
          <div className='logo-container'>
            <img className='logo' alt='logo' src={"https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/anystarr_icon.png"} />
          </div>
        </div>
      </Link>
      <LoginModal open={openConnectModal} mobile={mobile} onCancel={() => setOpenConnectModal(false)}></LoginModal>
      <LogoutModal show={logoutModal} onCancel={() => setLogoutModal(false)}></LogoutModal>


      <div className='right-container'>
        {mobile === false && <SearchInput />}
        {mobile === true && <img className={"w-[40px] h-[40px] mr-[4px]"} src={SearchIcon} onClick={goSearchPage} />}
        {
          userInfo?.displayName || userInfo?.avatarUrl ?
            mobile ? <div className={"flex items-center cursor-pointer pr-[16px]"} onClick={() => setGoPageModal(!goPageModal)}>
              <Avatar className={'avatar'} size={32} src={userInfo?.avatarUrl}></Avatar>
            </div> :
              <Popover
                trigger={"hover"}
                placement={'bottom'}
                content={content}
              >
                <div onClick={() => gotoShowcase(1)} className={"flex items-center cursor-pointer"}>
                  <Avatar size={48} src={userInfo?.avatarUrl}></Avatar>
                  <span className={"user_name_wrap"} style={{ marginLeft: "10px" }}>{userInfo?.displayName}</span>
                  <img className={"w-4 h-4 ml-4"} src={"https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/down_black.png"}></img>
                </div>
              </Popover>
            : <div onClick={() => setOpenConnectModal(true)} className={`link_wallet ${mobile ? '' : 'w-[100px]'}`}>
              Login
            </div>
        }
      </div>
      <SubNotAuthMobile
        isVisible={notAuthMobile}
        title={'Authorization Required'}
        confirmText={'Authorization'}
        closePopup={() => setNotAuthMobile(false)} />
      <MoreMobileModal isVisible={goPageModal} content={content} closePopup={() => setGoPageModal(false)}></MoreMobileModal>
    </div >);
};

const SearchInput = () => {
  const [open, setOpen] = useState(false);
  const [mobile, setMobile] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [currenSearchType, setCurrentSearchType] = useState(2); // 1 pid 2 name 3 佣金率
  const currenSearchTypeRef = useRef(2); // 1 pid 2 name 3 佣金率
  const [openHistory, setOpenHistory] = useState(false);
  const handleChange = (value) => {
    currenSearchTypeRef.current = value;
    setCurrentSearchType(value);
  };
  const router = useRouter();

  const onSearch = () => {
    router.push(`/?s=${searchName}&t=${currenSearchType}&state=${Math.floor(Math.random() * 10)}`);
    setSearchName("");
    setOpen(false);
  };

  const onHisSearch = (name, type) => {
    router.push(`/?s=${name}&t=${type}&state=${Math.floor(Math.random() * 10)}`);
    setSearchName("");
    setOpen(false);
  };

  useEffect(() => {
    setMobile(isMobile());
    setCurrentSearchType(2);
  }, [open]);

  useEffect(()=>{
    setOpenHistory(false);
  }, [open]);

  return <div>
    <img onClick={() => {

      // if (mobile) {
      //   router.push("/search");
      // } else {
      //   setOpen(!open);
      // }

      setOpen(!open);

    }} className={"search_icon !w-[40px] !h-[40px] object-cover mr-[15px]"} src={SearchIcon} />
    <Modal destroyOnClose={true} rootClassName={"global_search"} width={mobile ? null : 720} centered={!mobile} footer={null} title={null} closable={false} open={open} onCancel={() => setOpen(false)}>

      <div style={{
        display: "flex",
        justifyContent: "center"
      }}>
        <Popover
          overlayInnerStyle={{
            padding: "0",
            background: "#00000000",
            zIndex: 9999,
            boxShadow: "none",
            width: "720px"
          }} zIndex={9999} arrow={false} open={openHistory} onOpenChange={(v) => {
            setOpenHistory(v);
          }}

          getTooltipContainer={() => document.getElementById("searchBar-modal")} getPopupContainer={() => document.getElementById("searchBar-modal")}

          placement={"bottom"} trigger={"click"} content={
            <SearchDropDown onBlur={() => { }} hover={() => { }} show={() => { }} noHover={() => { }} onSearch={(e, item) => {
              setSearchName(item.name);
              setCurrentSearchType(Number(item.type));
              onHisSearch(item.name, Number(item.type));
              setOpenHistory(false);
            }} />}
        >
          <div id={"searchBar-modal"} className={"w-[720px]"}>
            <Searchbar
              handleChange={handleChange}
              closeHistory={() => setOpenHistory(false)}
              currenSearchType={currenSearchType}
              searchName={searchName}
              setSearchName={setSearchName}
              onSearch={onSearch}
            ></Searchbar>
          </div>
        </Popover>
      </div>
    </Modal>
  </div>;
};

export const Searchbar = ({ style = {}, leftStyle = {}, mobile = false, handleChange, currenSearchType, searchName, setSearchName, onSearch, loading = false, closeHistory }) => {
  return <div className={"m_wrap"}>
    <div style={style} className={`search_warp ${searchName ? "border_search" : ""}`}>
      <div onClick={(e) => {
        e.stopPropagation();
        closeHistory?.();
      }} style={leftStyle} className={"left_wrap !font-bold"}>
        <Select onFocus={() => {
          closeHistory?.();
        }} suffixIcon={<img src={ArrowDownIcon} className={"w-[16px] h-[16px] pointer-events-none"} />} popupMatchSelectWidth={false} onClick={(e) => {
          e.stopPropagation();
        }} rootClassName={"left_wrap !font-bold"} onChange={handleChange} labelRender={(v) => {
          return currenSearchType === 1 ? "Product Link" : "Product Name";
        }} defaultValue={currenSearchType} value={currenSearchType}>
          <Option key={2} value={2}>{mobile ? "Name" : "Product Name"}</Option>
          <Option key={1} value={1}>{mobile ? "Link" : "Product Link"}</Option>
        </Select>
      </div>
      <Input onClear={()=>{
        closeHistory();
        onSearch("", currenSearchType);
      }} allowClear={
        {
          clearIcon:<img className={"w-[16px] h-[16px]"} src={ClearIcon} />
        }
      } value={searchName} onChange={(e) => setSearchName(e.target.value)}
             onPressEnter={() => {
               closeHistory();
               onSearch(searchName, currenSearchType);
             }}
             placeholder={`Search Product ${currenSearchType === 1 ? "Link" : "Name"}`}
             className={"input"}></Input>
      <div onClick={() => {
        closeHistory();
        onSearch(searchName, currenSearchType);
      }} className={"right_wrap"}>
        {loading && <div className={"w-[48px] h-[48px] flex items-center justify-center"}><LoadingOutlined></LoadingOutlined></div>}
        {!loading && <img className={"w-[48px] h-[48px]"} src={SearchIcon} />}
      </div>
    </div>
  </div>;
};


export default Header;
