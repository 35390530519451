import { combineReducers } from 'redux';
import home from './home';
import user from './user';
import {
  fetchProductList,
  categoryFirstInfo,
  categorySecondInfo,
  fetchProductInfo,
  fetchAddCollect,
  fetchDeleteCollect,
  fetchUserGMV,
  fetchShowcasesList,
  fetchLikeProductList
} from './home-mobile';

const _reducers = combineReducers({
  home,
  user,
  fetchProductList,
  categoryFirstInfo,
  categorySecondInfo,
  fetchProductInfo,
  fetchAddCollect,
  fetchDeleteCollect,
  fetchUserGMV,
  fetchShowcasesList,
  fetchLikeProductList
});

function reducers(state, action) {
  return _reducers(state, action);
}

export default reducers;