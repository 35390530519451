
const initialProductListState = {
  productList: [],
  apiLoading: false,
  total: null,
  selectedList: {
    level0: [],
    level1: [],
    level2: [],
  },
  productListParams: {
    page: 1,      // 当前页
    pageSize: 40,     // 每页显示数量
    sort: 5,      // 默认筛选类型
    searchType: 2, // 默认搜索类型，1-link，2-name 
    searchName: '', // 默认搜索name
    // sortType: 0
  },
};
const initialCategoryFirstInfoState = {
  categoryFirst: []
};
const initialCategorySecondInfoState = {
  categorySecond: []
};
const initialProductInfoState = {
  productInfo: {}
};
const initialFetchAddCollectState = {
  addCollect: false
};
const initialFetchDeleteCollectState = {
  deleteCollect: false
};
const initialUserGMVState = {
  userGMV: {}
};
const initialShowcasesListState = {
  showcasesInfo: {},
  showcasesList: [],
  showcasesListParams: {
    page_size: 1,
    origin: 'SHOWCASE'
  }
};
const initialLikeProductListState = {
  likeProductInfo: {},
  likeProductList: [],
  likeProductListParams: {
    page: 1,      // 当前页
    pageSize: 20,     // 每页显示数量
  }
};

const fetchProductList = (state = initialProductListState, action) => {
  console.log('~productList', action, state);
  const { list, ...remainingParams } = action.payload || {};
  console.log('~productList12345656', remainingParams, list);
  switch (action.type) {
    case 'FETCH_PRODUCT_LIST':
      return { ...state, productListParams: remainingParams, selectedList: list ? list : remainingParams?.categoryIds ? state?.selectedList : [], apiLoading: true };
    case 'SET_SEARCH':
      return {
        ...state,
        productListParams: action.payload,
      };
    case 'FETCH_PRODUCT_LIST_SUCCESS':
      return { ...state, apiLoading: false, total: action.payload?.total, productList: state.page === 1 || state.productListParams.page === 1 ? action.payload?.records : [...state.productList, ...action.payload?.records] };
    case 'FETCH_PRODUCT_LIST_FAILURE':
      return { ...state, apiLoading: false };
    default:
      return { ...state, apiLoading: false };
  }
};
const categoryFirstInfo = (state = initialCategoryFirstInfoState, action) => {
  switch (action.type) {
    case 'FETCH_CATEGORY_FIRST_SUCCESS':
      return { ...state, categoryFirst: action.payload };

    default:
      return state;
  }
};
const categorySecondInfo = (state = initialCategorySecondInfoState, action) => {
  switch (action.type) {
    case 'FETCH_CATEGORY_SECOND_SUCCESS':
      return { ...state, categorySecond: action.payload };

    default:
      return state;
  }
};
const fetchProductInfo = (state = initialProductInfoState, action) => {
  switch (action.type) {
    case 'FETCH_PRODUCT_INFO':
      return { ...state, productInfo: {} };
    case 'FETCH_PRODUCT_INFO_SUCCESS':
      return { ...state, productInfo: action.payload };
    default:
      return state;
  }
};
const fetchAddCollect = (state = initialFetchAddCollectState, action) => {
  switch (action.type) {
    case 'FETCH_ADD_COLLECT_SUCCESS':
      return { addCollect: action.payload };
    default:
      return state;
  }
};
const fetchDeleteCollect = (state = initialFetchDeleteCollectState, action) => {
  console.log('~fetchDeleteCollect', action, state);
  switch (action.type) {
    case 'FETCH_DELETE_COLLECT_SUCCESS':
      return { deleteCollect: action.payload };
    default:
      return state;
  }
};
const fetchUserGMV = (state = initialUserGMVState, action) => {
  console.log('~userGMV22222', action, state);
  switch (action.type) {
    case 'FETCH_USER_GMV_SUCCESS':
      return { userGMV: action.payload };
    default:
      return state;
  }
};
const fetchShowcasesList = (state = initialShowcasesListState, action) => {
  console.log('~fetchShowcasesList', action, state, state?.showcasesListParams?.page_token === '');
  switch (action.type) {
    case 'FETCH_SHOWCASES_LIST':
      return { ...state, showcasesListParams: action.payload };
    case 'FETCH_SHOWCASES_LIST_SUCCESS':
      return {
        ...state,
        showcasesInfo: action.payload.data,
        showcasesList: state?.showcasesListParams?.page_token === ''
          ? action.payload.data?.products :
          [...state.showcasesList, ...action.payload.data.products]
      };
    default:
      return state;
  }
};
const fetchLikeProductList = (state = initialLikeProductListState, action) => {
  console.log('~fetchLikeProductList', action, state);
  switch (action.type) {
    case 'FETCH_LIKE_PRODUCT_LIST':
      return { ...state, likeProductListParams: action.payload };
    case 'FETCH_LIKE_PRODUCT_LIST_SUCCESS':
      return {
        ...state,
        likeProductInfo: action.payload,
        likeProductList: state.page === 1 || state.likeProductListParams.page === 1
          ? action.payload?.records :
          [...state.likeProductList, ...action.payload?.records]
      };
    default:
      return state;
  }
};

export {
  fetchProductList,
  categoryFirstInfo,
  categorySecondInfo,
  fetchProductInfo,
  fetchAddCollect,
  fetchDeleteCollect,
  fetchUserGMV,
  fetchShowcasesList,
  fetchLikeProductList
};