import { Button, Modal } from "antd";
import SizeBox from "@/components/SizeBox";
import { FailIcon } from "@/config/config";
import host from "@/utils/host";


const NotAuthModal = ({ show, mobile, onCancel }) => {
  const title = mobile ? '' : 'Tips';
  return (

    <Modal title={title} centered footer={null} width={350} open={show} onCancel={(e) => {
      e.stopPropagation();
      onCancel();
    }}>
      <div style={{ padding: "0px" }}>
        <div style={{ textAlign: "center", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {mobile ? <img style={{ width: "60px", height: "60px", marginTop: '20px' }} src={FailIcon} />
            : <img style={{ width: "100px", height: "100px", }} src={FailIcon} />}

          <SizeBox h={20}></SizeBox>
          <div>
            Login failed, please authorize <span style={{
              color: "#F0774A"
            }}>all permissions.</span>
          </div>
        </div>
        <SizeBox h={15}></SizeBox>
      </div>
    </Modal >
  );
};

export default NotAuthModal;
