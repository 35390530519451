import { useEffect } from 'react';
import { lineMdClose } from "@/config/config";
import host from "@/utils/host";
import styles from './index.module.scss';

const PopupMobile = ({ isVisible, title, resetText, confirmText, closePopup, resetPopup, confirmPopup }) => {
  useEffect(() => {
    if (isVisible) {
      // 禁止页面滚动
      document.body.style.overflow = 'hidden';
    } else {
      // 恢复页面滚动
      document.body.style.overflow = '';
    }
    // 在组件卸载时恢复页面滚动
    return () => {
      document.body.style.overflow = '';
    };
  }, [isVisible]);
  return (
    isVisible && <div className={styles.overlay} onClick={(e) => {
      e.preventDefault();
      closePopup();
    }}
      onTouchMove={(e) => {
        e.preventDefault(); // 阻止默认的触摸移动行为，防止页面滚动
      }}>
      <div className={`${styles.popup} ${isVisible ? styles['popup-enter'] : styles['popup-exit']
        }`}
        onClick={(e) => e.stopPropagation()} // 阻止事件冒泡，避免点击内容关闭 Popup
      >
        <div className={styles.header}>
          <div className={styles.headerLeft}>   {title}</div>
          <img src={lineMdClose} onClick={closePopup} className={styles.headerRight} />
        </div>
        <div className={styles.content}>
          <ul className={styles.firstTitle}>
            <li > <span className={styles.diffText}>Attention! Please make sure that the Tiktok account you logged in on your webpage or App is the same as the one you logged in at anyStarr, otherwise the authorization will fail!</span></li>
            <li >After authorization, you can use more functions, including but not limited to:</li>
            <ul className={styles.subTitle}>
              <li>View your <span className={styles.diffText}>Tiktok marketplace performance</span> </li>
              <li>View your <span className={styles.diffText}>showcase information</span></li>
              <li><span className={styles.diffText}>Increase commission</span> on your showcase products (under development)</li>
              <li>Check the progress of your <span className={styles.diffText}>sample application</span> (under development)</li>
            </ul>
            <li>Please <span className={styles.diffText}>select all</span> authorized content so that we can help you improve your Tiktok e-commerce performance.
            </li>
            <li>The data will not be used for any other purposes.</li>
            <li>You can cancel the authorization in the personal center.</li>
          </ul>
        </div>
        <div className={styles.footer}>
          <a href={`${host}/api/v2/tiktok/login`}>
            {confirmText}</a>
        </div>
      </div>
    </div>
  );
};
export default PopupMobile;