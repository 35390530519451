import {
  FETCH_PRODUCT_LIST,
  FETCH_PRODUCT_LIST_SUCCESS,
  FETCH_PRODUCT_LIST_FAILURE,
  FETCH_CATEGORY_FIRST,
  FETCH_CATEGORY_FIRST_SUCCESS,
  FETCH_CATEGORY_FIRST_FAILURE,
  FETCH_CATEGORY_SECOND,
  FETCH_CATEGORY_SECOND_SUCCESS,
  FETCH_CATEGORY_SECOND_FAILURE,
  SET_SEARCH,
  FETCH_PRODUCT_INFO,
  FETCH_PRODUCT_INFO_SUCCESS,
  FETCH_PRODUCT_INFO_FAILURE,
  FETCH_ADD_COLLECT,
  FETCH_ADD_COLLECT_SUCCESS,
  FETCH_ADD_COLLECT_FAILURE,
  FETCH_DELETE_COLLECT,
  FETCH_DELETE_COLLECT_SUCCESS,
  FETCH_DELETE_COLLECT_FAILURE,
  FETCH_USER_GMV,
  FETCH_USER_GMV_SUCCESS,
  FETCH_USER_GMV_FAILURE,
  FETCH_SHOWCASES_LIST,
  FETCH_SHOWCASES_LIST_SUCCESS,
  FETCH_SHOWCASES_LIST_FAILURE,
  FETCH_LIKE_PRODUCT_LIST,
  FETCH_LIKE_PRODUCT_LIST_SUCCESS,
  FETCH_LIKE_PRODUCT_LIST_FAILURE,
} from '../../constants/ActionTypes';

// 获取首页商品列表
export const fetchProductList = (payload) => ({
  type: FETCH_PRODUCT_LIST,
  payload,

});
export const fetchProductListSuccess = (data) => ({
  type: FETCH_PRODUCT_LIST_SUCCESS,
  payload: data,
});
export const fetchProductListFailure = (error) => ({
  type: FETCH_PRODUCT_LIST_FAILURE,
  payload: error,
});

// 搜索传参
export const setSearch = (payload) => ({
  type: SET_SEARCH,
  payload,
});

// 获取商品类一级类目
export const fetchCategoryFirst = () => ({
  type: FETCH_CATEGORY_FIRST,
});
export const fetchCategoryFirstSuccess = (data) => ({
  type: FETCH_CATEGORY_FIRST_SUCCESS,
  payload: data,
});
export const fetchCategoryFirstFailure = (error) => ({
  type: FETCH_CATEGORY_FIRST_FAILURE,
  payload: error,
});

// 获取商品类二级类目
export const fetchCategorySecond = (payload) => ({
  type: FETCH_CATEGORY_SECOND,
  payload
});
export const fetchCategorySecondSuccess = (data) => ({
  type: FETCH_CATEGORY_SECOND_SUCCESS,
  payload: data,
});
export const fetchCategorySecondFailure = (error) => ({
  type: FETCH_CATEGORY_SECOND_FAILURE,
  payload: error,
});

// 获取商品详情
export const fetchProductInfo = (payload) => ({
  type: FETCH_PRODUCT_INFO,
  payload,
});
export const fetchProductInfoSuccess = (data) => ({
  type: FETCH_PRODUCT_INFO_SUCCESS,
  payload: data,
});
export const fetchProductInfoFailure = (error) => ({
  type: FETCH_PRODUCT_INFO_FAILURE,
  payload: error,
});

// 增加收藏
export const fetchAddCollect = (payload) => ({
  type: FETCH_ADD_COLLECT,
  payload,
});
export const fetchAddCollectSuccess = (data) => ({
  type: FETCH_ADD_COLLECT_SUCCESS,
  payload: data,
});
export const fetchAddCollectFailure = (error) => ({
  type: FETCH_ADD_COLLECT_FAILURE,
  payload: error,
});

// 删除收藏
export const fetchDeleteCollect = (payload) => ({
  type: FETCH_DELETE_COLLECT,
  payload,
});
export const fetchDeleteCollectSuccess = (data) => ({
  type: FETCH_DELETE_COLLECT_SUCCESS,
  payload: data,
});
export const fetchDeleteCollectFailure = (error) => ({
  type: FETCH_DELETE_COLLECT_FAILURE,
  payload: error,
});

// 获取达人GMV
export const fetchUserGMV = () => ({
  type: FETCH_USER_GMV,
});
export const fetchUserGMVSuccess = (data) => ({
  type: FETCH_USER_GMV_SUCCESS,
  payload: data,
});
export const fetchUserGMVFailure = (error) => ({
  type: FETCH_USER_GMV_FAILURE,
  payload: error,
});

// 获取商品橱窗列表
export const fetchShowcasesList = (payload) => ({
  type: FETCH_SHOWCASES_LIST,
  payload,
});
export const fetchShowcasesListSuccess = (data) => ({
  type: FETCH_SHOWCASES_LIST_SUCCESS,
  payload: data,
});
export const fetchShowcasesListFailure = (error) => ({
  type: FETCH_SHOWCASES_LIST_FAILURE,
  payload: error,
});

// 获取商品收藏列表
export const fetchLikeProductList = (payload) => ({
  type: FETCH_LIKE_PRODUCT_LIST,
  payload,
});
export const fetchLikeProductListSuccess = (data) => ({
  type: FETCH_LIKE_PRODUCT_LIST_SUCCESS,
  payload: data,
});
export const fetchLikeProductListFailure = (error) => ({
  type: FETCH_LIKE_PRODUCT_LIST_FAILURE,
  payload: error,
});