import { Spin, message } from 'antd';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState, useRef } from 'react';
import useLogin from "@/hooks/useLogin";
import Empty from '@/components-mobile/Empty';
import LoginModal from "@/components/LoginModal";
import ListCard from '@/components-mobile/ListCard';
import CommonLoading from "@/components/CommonLoading";
import { APIGetLinkByPid, APIH5ClickStatistics } from "@/api";
import { generateRandomStringWithTimestamp } from "@/utils/utils";
import styles from './index.module.scss';
import Condition from './components/condition';
import SearchBar from '../../components-mobile/Home/search-bar';
import { fetchProductList, fetchAddCollect, fetchDeleteCollect } from '../../redux/actions/home-mobile';

const HomeMobile = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { needLogin } = useLogin();
  const [openId, setOpenId] = useState('');
  const [loading, setLoading] = useState(false);   // 页面触底loading
  const [page, setPage] = useState(1); // 当前页数，第一页已经加载
  const [hasLoaded, setHasLoaded] = useState(false);  // 标记数据是否加载完成
  const [showConnectTips, setShowConnectTips] = useState(false);  // 登录提示
  const loadingRef = useRef(false); // 使用 ref 来标记是否正在加载
  const { productList, apiLoading, total, productListParams } = useSelector((state) => {
    return state.fetchProductList;
  });

  // 页面加载时调用接口
  useEffect(() => {
    const randomNum = localStorage.getItem("randomNum") ?? generateRandomStringWithTimestamp();
    if (!localStorage.getItem("randomNum")) {
      localStorage.setItem("randomNum", randomNum);
    }
    let params;
    if (productListParams?.sort === 5) {
      params = { page: 1, uuid: randomNum };
    } else {
      params = { page: 1 };
    }
    dispatch(fetchProductList({ ...productListParams, ...params }));
  }, []);
  useEffect(() => {
    // 如果加载的数据量已经达到总数，停止加载更多
    if (productList.length >= total) {

      loadingRef.current = true;
      setLoading(false);
    } else {
      setLoading(true);
      loadingRef.current = false;
    }
    if (total === 0 && productListParams.page === 1) {
      setHasLoaded(true);
    } else {
      setHasLoaded(false);
    }
  }, [productList]);
  // 监听滚动事件
  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
      // 判断是否滚动到底部
      if (scrollTop + clientHeight >= scrollHeight - 100) {
        loadMoreData();
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [productListParams, loading]);

  const loadMoreData = () => {
    if (loadingRef.current) return;
    loadingRef.current = true; // 标记为正在加载
    setLoading(true);
    setPage(prevPage => prevPage + 1);
    const randomNum = localStorage.getItem("randomNum") ?? generateRandomStringWithTimestamp();
    if (!localStorage.getItem("randomNum")) {
      localStorage.setItem("randomNum", randomNum);
    }
    let params;
    if (productListParams?.sort === 5) {
      params = { page: productListParams.page + 1, uuid: randomNum };
    } else {
      params = { page: productListParams.page + 1 };
    }
    dispatch(fetchProductList({ ...productListParams, ...params }));
  };

  const goSearchPage = () => {
    router.push({
      pathname: '/search-page-mobile/'
    });
  };

  const collectChange = (e, data, collected) => {
    e.stopPropagation();
    let user = localStorage.getItem("user");
    user = JSON.parse(user);
    if (needLogin) {
      setShowConnectTips(true);
      return;
    }
    if (collected) {
      dispatch(fetchDeleteCollect({ uid: user.id, itemId: data.productId }));
    } else {
      if (typeof window !== 'undefined' && window.gtag) {
        window.gtag('event', 'collect_h5', {
          event_category: '首页收藏_h5',
          event_label: '首页收藏_h5',
        });
      }
      dispatch(fetchAddCollect({ uid: user.id, itemId: data.productId }));
    }
  };
  const addChange = (e, data) => {
    if (typeof window !== 'undefined' && window.gtag) {
      window.gtag('event', 'add_showcase_h5', {
        event_category: '首页加厨_h5',
        event_label: '首页加厨_h5',
      });
    }
    APIH5ClickStatistics({ productIds: data?.productId.toString() }).then().catch();
    e.stopPropagation();
    if (needLogin) {
      setShowConnectTips(true);
      return;
    }
    try {
      if (data.needApplyLink) {
        APIGetLinkByPid(data.productId).then(resp => {
          if (resp?.data.url) {
            const url = resp.data.url;
            window.location.href = url;
            console.log('~url', url);
          } else {
            message.info("Sorry, The product has been taken down!");
          }
        }).catch(e => {
          message.error("Sorry, Unknown exception!");
        }).finally(() => {
        });
      } else {
        const url = data.url;
        window.location.href = url;
      }
    } catch (e) {
      message.error("Sorry, Unknown exception!");
    }
  };
  const goDetailPage = (data) => {
    router.push(`/details-mobile/${data.productId}`);
  };
  return (
    <div className={styles.homeMobile}>
      <SearchBar onPress={goSearchPage} productListParams={productListParams} />
      <Condition />
      <Spin spinning={apiLoading && page === 1} indicator={<CommonLoading />}>
        <div className={styles.content}>
          {productList.length !== 0 && productList.map((item, index) => (
            <ListCard sourceData={item} key={index} openId={openId} typePage='home' collectChange={collectChange} goDetailPage={goDetailPage} addChange={addChange}
              onPress={(e, item) => {
                e.stopPropagation();
                if (item?.productId === openId || item?.productId === undefined) {
                  setOpenId('');
                } else {
                  setOpenId(item.productId);
                }
              }} />
          ))}
          {page !== 1 && loading && <div className={"flex items-center justify-center"}><CommonLoading className={"w-[30px] h-[30px]"} /></div>}
          {hasLoaded && <Empty result={`Sorry，there are no results for “${productListParams.searchName ? productListParams.searchName : 'unknown'}”`} />}
        </div>
      </Spin>
      <LoginModal open={showConnectTips} mobile={true} onCancel={() => setShowConnectTips(false)}></LoginModal>
    </div>
  );
};
export default HomeMobile;