import { useEffect } from 'react';
import { Icon } from "@iconify/react";
import styles from './index.module.scss';

const PopupMobile = ({ isVisible, title, children, resetText, confirmText, closePopup, resetPopup, confirmPopup }) => {
  useEffect(() => {
    if (isVisible) {
      // 禁止页面滚动
      document.body.style.overflow = 'hidden';
    } else {
      // 恢复页面滚动
      document.body.style.overflow = '';
    }
    // 在组件卸载时恢复页面滚动
    return () => {
      document.body.style.overflow = '';
    };
  }, [isVisible]);
  return (
    isVisible && <div className={styles.overlay} onClick={(e) => {
      e.preventDefault();
      closePopup();
    }}
      onTouchMove={(e) => {
        e.preventDefault(); // 阻止默认的触摸移动行为，防止页面滚动
      }}
    >
      <div className={`${styles.popup} ${isVisible ? styles['popup-enter'] : styles['popup-exit']
        }`}
        onClick={(e) => e.stopPropagation()} // 阻止事件冒泡，避免点击内容关闭 Popup
      >
        <div className={styles.header}>
          <div className={styles.headerLeft}></div>
          <div className={styles.headerTitle}>
            {title}
          </div>
          <div className={styles.headerRight} onClick={closePopup}>
            <Icon icon='jam:close-circle-f' className={styles.headerRightIcon} />
          </div>
        </div>
        <div className={styles.content}>{children}</div>
        <div className={styles.footer}>
          <div className={styles.footerReset} onClick={resetPopup}>
            {resetText}
          </div>
          <div className={styles.footerConfirm} onClick={confirmPopup}>
            {confirmText}
          </div>
        </div>
      </div>
    </div>
  );
};
export default PopupMobile;