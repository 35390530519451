import { all } from 'redux-saga/effects';
import userSagas from './user/index';
import {watchProductList} from './home-mobile';

export default function* rootSagas() {
  yield all([
    ...userSagas,
    watchProductList()
  ]);
}
