import PropTypes from 'prop-types';
import DynamicAntdTheme from 'dynamic-antd-theme';
import Header from './Header';
import { BackTop } from "antd";
import ConnectTikTipsModal from "@/components/connectTikTipsModal";
import LoginModal from "@/components/LoginModal";
import { useRouter } from "next/router";
import {APIGetCheckV2Permissions} from "@/api";
import {useEffect} from "react";
import useDispatchAction from "@/hooks/useDisptachAction";
import {setPermission} from "@/redux/actions/home";
import ToHomePageLink from "@/components/ToHomePageIcon";

const hideHeader = ["/influencers-2", "/home", "/search"];

function Layout({ children }) {

  const router = useRouter();
  return (
    <div style={{
      overflow: "hidden"
    }} className={"overflow"}>
      <style jsx>{`
        .content-container {
          padding-top: 80px;
        }
      `}</style>

      {
        !hideHeader.includes(router.pathname) && <Header />
      }
      <div className='content-container'>
        {children}
      </div>
      {
        !hideHeader.includes(router.pathname) && <div className={"app-footer"}>
          <div className={"footer-gray"}>
            <div className={"footer-g-content"}>
              <a href={"/Terms_and_Conditions_anyStarr.html"} className={"footer-link-2 link-item"}>
                Terms and Conditions
              </a>
              <a href={"/privacy_policy.html"} className={"footer-link-2 link-item"}>
                Privacy Policy
              </a>
              <a href={"/helpCenter"} className={"footer-link-2 link-item"}>
                Help center
              </a>
            </div>
          </div>
        </div>
      }
      <BackTop style={{
        right: "30px"
      }}></BackTop>


      <ToHomePageLink></ToHomePageLink>

    </div>
  );
}
export default Layout;
Layout.propTypes = {
  children: PropTypes.any
};

Layout.defaultProps = {
  children: null
};
