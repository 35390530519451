import {
  fetchProductListSuccess,
  fetchProductListFailure,
  fetchCategoryFirstSuccess,
  fetchCategoryFirstFailure,
  fetchCategorySecondSuccess,
  fetchCategorySecondFailure,
  fetchProductInfoSuccess,
  fetchProductInfoFailure,
  fetchAddCollectSuccess,
  fetchAddCollectFailure,
  fetchDeleteCollectSuccess,
  fetchDeleteCollectFailure,
  fetchUserGMVSuccess,
  fetchUserGMVFailure,
  fetchShowcasesListSuccess,
  fetchShowcasesListFailure,
  fetchLikeProductListSuccess,
  fetchLikeProductListFailure
} from '../actions/home-mobile';
import {
  FETCH_PRODUCT_LIST,
  FETCH_CATEGORY_FIRST,
  FETCH_CATEGORY_SECOND,
  FETCH_PRODUCT_INFO,
  FETCH_ADD_COLLECT,
  FETCH_DELETE_COLLECT,
  FETCH_USER_GMV,
  FETCH_SHOWCASES_LIST,
  FETCH_LIKE_PRODUCT_LIST
} from '../../constants/ActionTypes';
import {
  APIGetProductList,
  APIGetCategoryFirst,
  APIGetCategorySecond,
  APIGetProductInfo,
  APIAddFavoriteItems,
  APIDeleteFavoriteItems,
  APIGetGMV,
  APIGetMyShowCase,
  APIGetLikeProductList
} from '@/api/index.js';
import { call, put, takeEvery } from 'redux-saga/effects';

function* getProductList(action) {  // 商品列表
  const { list, ...remainingParams } = action.payload || {};
  console.log('~productList123456560000', remainingParams);
  const res = yield call(APIGetProductList, JSON.stringify(remainingParams));
  if (res.code === '1') {
    yield put(fetchProductListSuccess(res.data.list));
  } else {
    yield put(fetchProductListFailure(res.message));
  }
}

function* getCategoryFirst(action) {  // 一级类目
  const res = yield call(APIGetCategoryFirst);
  if (res.code === '1') {
    yield put(fetchCategoryFirstSuccess(res.data.result));
  } else {
    yield put(fetchCategoryFirstFailure(res.message));
  }
}

function* getCategorySecond(action) {  // 二级类目
  const res = yield call(APIGetCategorySecond, action.payload);
  if (res.code === '1') {
    yield put(fetchCategorySecondSuccess(res.data.result));
  } else {
    yield put(fetchCategorySecondFailure(res.message));
  }
}
function* getProductInfo(action) {  // 商品详情
  const res = yield call(APIGetProductInfo, action.payload);
  if (res.code === '1') {
    yield put(fetchProductInfoSuccess(res.data.product));
  } else {
    yield put(fetchProductInfoFailure(res.message));
  }
}
function* addCollect(action) {  // 增加收藏
  const res = yield call(APIAddFavoriteItems, action.payload);
  if (res.code === '1') {
    yield put(fetchAddCollectSuccess(res.data.result));
  } else {
    yield put(fetchAddCollectFailure(res.message));
  }
}
function* deleteCollect(action) {  // 删除收藏
  const res = yield call(APIDeleteFavoriteItems, action.payload);
  if (res.code === '1') {
    yield put(fetchDeleteCollectSuccess(res.data.result));
  } else {
    yield put(fetchDeleteCollectFailure(res.message));
  }
}
function* getUserGMV(action) {  // 获取达人gmv
  const res = yield call(APIGetGMV, action.payload);
  if (res.code === '1') {
    yield put(fetchUserGMVSuccess(res.data.returnMap));
  } else {
    yield put(fetchUserGMVFailure(res.message));
  }
}
function* getShowcasesList(action) {  // 橱窗列表
  const res = yield call(APIGetMyShowCase, action.payload);
  if (res.code === '1') {
    console.log('~333333showcases', res);
    yield put(fetchShowcasesListSuccess(res.data.result));
  } else {
    yield put(fetchShowcasesListFailure(res.message));
  }
}
function* getLikeProductList(action) {  // 橱窗列表
  const res = yield call(APIGetLikeProductList, JSON.stringify(action.payload));
  if (res.code === '1') {
    console.log('~33333333333getLikeProductList', res);
    yield put(fetchLikeProductListSuccess(res.data.result));
  } else {
    yield put(fetchLikeProductListFailure(res.message));
  }
}

// Saga watcher: 监听 FETCH_LIST_REQUEST Action
export function* watchProductList() {
  console.log('~监听到watchProductList');
  yield takeEvery(FETCH_PRODUCT_LIST, getProductList);
  yield takeEvery(FETCH_CATEGORY_FIRST, getCategoryFirst);
  yield takeEvery(FETCH_CATEGORY_SECOND, getCategorySecond);
  yield takeEvery(FETCH_PRODUCT_INFO, getProductInfo);
  yield takeEvery(FETCH_ADD_COLLECT, addCollect);
  yield takeEvery(FETCH_DELETE_COLLECT, deleteCollect);
  yield takeEvery(FETCH_USER_GMV, getUserGMV);
  yield takeEvery(FETCH_SHOWCASES_LIST, getShowcasesList);
  yield takeEvery(FETCH_LIKE_PRODUCT_LIST, getLikeProductList);
}
