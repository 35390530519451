import React, { useEffect } from 'react';
import styles from "./index.module.scss";
import SizeBox from "@/components/SizeBox";
import host from "@/utils/host";
import { Modal } from "antd";

const LoginModal = ({ open, mobile, onCancel }) => {
  useEffect(() => {
    if (open) {
      // 禁止页面滚动
      document.body.style.overflow = 'hidden';
    } else {
      // 恢复页面滚动
      document.body.style.overflow = '';
    }
    // 在组件卸载时恢复页面滚动
    return () => {
      document.body.style.overflow = '';
    };
  }, [open]);
  return <Modal title={<div className={"flex items-center"} >
    <div className='logo-container !pl-0'>
      <img className='logo' alt='logo' src={"https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/anystarr_icon.png"} />
    </div>
  </div>} centered footer={null} open={open} onCancel={(e) => {
    e.stopPropagation();
    onCancel();
  }}>
    <div className={styles.login_modal}>
      <div>
        <div className={styles.login_title}>Login</div>
        <div className={styles.title_desc}>
          <div className={"flex items-start"}>
            <div className={"h-[20px] flex items-center shrink-0"}>
              <div className={"w-1 h-1 bg-[#222] mr-1 rounded-full shrink-0"}></div>
            </div>
            <div>
              Log in with your TikTok account to earn <span>more commissions</span>!
            </div>
          </div>
          <div className={"flex items-start"}>
            <div className={"h-[20px] flex items-center shrink-0"}>
              <div className={"w-1 h-1 bg-[#222] mr-1 rounded-full"}></div>
            </div>
            <div>
              Please <span>select all</span> authorized content so that we can help you improve your Tiktok e-commerce performance.
            </div>
          </div>
        </div>
      </div>
      {mobile ? <SizeBox h={14}></SizeBox> : <SizeBox h={26}></SizeBox>}
      <a href={`${host}/api/v1/tiktok/oauth`}>
        <div className={"link_wallet !h-[40px]"}>
          Log in with your Tiktok account
        </div>
      </a>
      <div className={"bg-[#EDEDED] h-[1px] w-full my-6"}></div>
      <div className={`${mobile ? styles.helpMobile : styles.help_wrap}`}>
        <a className={"mr-6"} href={"/Terms_and_Conditions_anyStarr.html"} target={"_blank"}>Terms and Conditions {" "} </a>
        <a href={"/privacy_policy.html"} target={"_blank"}> {" "} Privacy Policy </a>
      </div>
    </div>
  </Modal>;
};

export default LoginModal;
