import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from "@iconify/react";
import { fetchCategorySecond } from '@/redux/actions/home-mobile';
import styles from './index.module.scss';

const FilterInfo = ({ categoryFirst, defaultFilter, getFilterData, resetFilter, onDataChange, onReset }) => {
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [currentLevel, setCurrentLevel] = useState(0); // 当前层级，0为第一层
  const [subCategories, setSubCategories] = useState(categorySecond); // 二级数据
  const [thirdLevelCategories, setThirdLevelCategories] = useState([]); // 三级数据
  const [selectedTab, setSelectedTab] = useState('category');
  const [selectedRateValue, setSelectedRateValue] = useState(defaultFilter?.commissionTypes?.split(',')?.map(Number) || []); // 存储Rate选中值
  const [selectedSaleValue, setSelectedSaleValue] = useState(defaultFilter?.earnPerTypes?.split(',')?.map(Number) || []); // 存储Sale选中值

  const { categorySecond } = useSelector((state) => {
    return state.categorySecondInfo;
  });
  const { selectedList } = useSelector((state) => {
    return state.fetchProductList;
  });
  const [selectedItems, setSelectedItems] = useState(selectedList || {
    level0: [],
    level1: [],
    level2: [],
  });

  const commissionRate = [{
    value: 1,
    label: '< 15%',
  },
  {
    value: 2,
    label: '15% - 20%',
  },
  {
    value: 3,
    label: '20% - 25%',
  },
  {
    value: 4,
    label: '25% - 30%',
  },
  {
    value: 5,
    label: '> 30%',
  }];

  const earnPerSale = [{
    value: 1,
    label: '<$3',
  },
  {
    value: 2,
    label: '$3 - $5',
  },
  {
    value: 3,
    label: '$5 - $10',
  },
  {
    value: 4,
    label: '$10 - $20',
  },
  {
    value: 5,
    label: '>$20',
  },
  ];

  useEffect(() => {
    if (categoryFirst && categoryFirst.length > 0 && currentLevel === 0) {
      const updatedCategories = categoryFirst.map(item => ({
        id: item.categoryId,
        name: item.name,
        selected: selectedItems.level0?.some(selectedItem => selectedItem === item.categoryId) || false, // 初始化选中状态为 false
      }));
      setList(updatedCategories);
    }
    if (categorySecond && categorySecond.length > 0) {
      if (currentLevel === 1) {
        const updatedCategories = categorySecond.map(item => ({
          id: item.categoryId,
          name: item.name,
          selected: selectedItems.level0?.some(selectedItem => selectedItem === item.parentId) || selectedItems.level1?.some(selectedItem => selectedItem === item.categoryId),
        }));
        const updatedSelected = updatedCategories.filter(item => item.selected).map(item => item.id);
        setSubCategories(updatedCategories);
        setSelectedItems(prev => ({ ...prev, level1: updatedSelected }));
      } else if (currentLevel === 2) {
        const updatedCategories = categorySecond.map(item => ({
          id: item.categoryId,
          name: item.name,
          selected: selectedItems.level1?.some(selectedItem => selectedItem === item.parentId) || selectedItems.level2?.some(selectedItem => selectedItem === item.categoryId),
        }));
        setThirdLevelCategories(updatedCategories);
      }

    }
    if (resetFilter) {
      clearSelected();
      onReset();
    }
    if (getFilterData) {
      onDataChange(selectedItems, selectedRateValue, selectedSaleValue);

    }
  }, [categoryFirst, categorySecond, resetFilter, getFilterData]);

  // 清除选中内容
  const clearSelected = () => {
    setSelectedItems({
      level0: [],
      level1: [],
      level2: [],
    });
    const updatedList = list?.map((item) => ({
      ...item,
      selected: false,
    }));
    setList(updatedList);
    const updatedSubCategories = subCategories?.map((item) => ({
      ...item,
      selected: false,
    }));
    setSubCategories(updatedSubCategories);
    const updatedThirdLevelCategories = thirdLevelCategories?.map((item) => ({
      ...item,
      selected: false,
    }));
    setThirdLevelCategories(updatedThirdLevelCategories);
    setSelectedRateValue([]);
    setSelectedSaleValue([]);
  };
  // 切换选中状态
  const handleCheckBoxClick = (id, level, category) => {
    const updatedList = category.map(item =>
      item.id === id ? { ...item, selected: !item.selected } : item
    );
    const updatedSelected = updatedList.filter(item => item.selected).map(item => item.id);
    if (level === 0) {
      setList(updatedList);
      setSelectedItems(prev => ({ ...prev, level0: updatedSelected }));
    } else if (level === 1) {
      setSubCategories(updatedList);
      setSelectedItems(prev => ({ ...prev, level1: updatedSelected }));
    } else {
      setThirdLevelCategories(updatedList);
      setSelectedItems(prev => ({ ...prev, level2: updatedSelected }));
    }
  };

  // 处理返回按钮
  const handleBack = () => {
    setCurrentLevel(currentLevel - 1);
    if (currentLevel === 2) setThirdLevelCategories([]); // 清空三级数据
    if (currentLevel === 1) setSubCategories([]); // 清空二级数据

  };
  // 处理点击进入下一层级
  const handleCategoryClick = (parentId, level, isSelected) => {
    dispatch(fetchCategorySecond({ parentId }));
    if (level === 2) return;
    setTimeout(() => {
      setCurrentLevel(level + 1); // 进入下一级
    }, 200);

  };

  // 渲染层级数据
  const renderCategories = (categories, level) => {
    return categories?.map((item, index) => (
      <div className={styles.categoryList} key={index}>
        <div className={styles.checkBox} onClick={() => handleCheckBoxClick(item.id, level, categories)}>
          {!item.selected ? (
            <Icon icon='solar:check-circle-line-duotone' className={styles.unSelect} />
          ) : (
            <Icon icon='solar:check-circle-bold-duotone' className={styles.select} />
          )}
        </div>
        <div className={styles.categoryTitle} onClick={() => handleCategoryClick(item.id, level, item.selected)}>{item.name}</div>
        {(level === 0 || level === 1) && (
          <Icon
            icon='lucide:chevron-right'
            className={styles.categoryGo}
            onClick={() => handleCategoryClick(item.id, level, item.selected)}
          />
        )}
      </div>
    ));
  };

  // 切换按钮的选中状态
  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };
  // 选择commissionRate
  const selectRate = (value) => {
    const index = selectedRateValue.indexOf(value);
    if (index === -1) {
      setSelectedRateValue([...selectedRateValue, value]);
    } else {
      return setSelectedRateValue(selectedRateValue.filter(item => item !== value));
    }
  };

  // 选择Sale
  const selectSale = (value) => {
    const index = selectedSaleValue.indexOf(value);
    if (index === -1) {
      setSelectedSaleValue([...selectedSaleValue, value]);
    } else {
      return setSelectedSaleValue(selectedSaleValue.filter(item => item !== value));
    }
  };

  return (<div className={styles.filterInfo}>
    <div className={styles.tab}>
      <div className={`${styles.tabCategory} ${selectedTab === 'category' ? styles.selected : ''}`}
        onClick={() => handleTabClick('category')}> Category </div>
      <div className={`${styles.tabCategory} ${selectedTab === 'commission' ? styles.selected : ''}`}
        onClick={() => handleTabClick('commission')}> Commission</div>
    </div>
    {selectedTab === 'category' ?
      <div className={styles.content}>
        {currentLevel > 0 && (
          <div className={styles.backGroup} onClick={handleBack}>
            <Icon icon='lucide:chevron-left' className={styles.backIcon} />
            <span className={styles.backText}>Return</span>
          </div>
        )}
        <div >
          {currentLevel === 0 && renderCategories(list, 0)}
          {currentLevel === 1 && renderCategories(subCategories, 1)}
          {currentLevel === 2 && renderCategories(thirdLevelCategories, 2)}
        </div>
      </div>
      : <div className={styles.commission}>
        <div className={styles.title}>Commission Rate</div>
        <div className={styles.group}>
          {commissionRate.map((item, index) => (
            <div className={`${styles.warp} ${selectedRateValue.indexOf(item.value) !== -1 ? styles.active : ''}`}
              key={index}
              onClick={() => selectRate(item.value)}>
              {item.label}
            </div>))}

        </div>
        <div className={styles.title}>Earn Per Sale</div>
        <div className={styles.group}>
          {earnPerSale.map((item, index) => (
            <div className={`${styles.warp} ${selectedSaleValue.indexOf(item.value) !== -1 ? styles.active : ''}`}
              key={index}
              onClick={() => selectSale(item.value)}>
              {item.label}
            </div>))}
        </div>
      </div>}
  </div>);
};

export default FilterInfo;