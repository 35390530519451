import dynamic from "next/dynamic";
import PageContainer from "@/components/page-comtainer";
// import Home from './home';
import HomeMobile from "./home-mobile";
const Home = dynamic(() => import("@/pages/beta"), {
  ssr: false, // 如果组件只在客户端渲染，可以设置为 false
  loading: () => <div></div>, // 可选：提供一个加载状态
});

export async function getServerSideProps(context) {
  const userAgent = context.req.headers['user-agent'] || '';
  const isH5 = /Android|webOS|iPhone|iPod|BlackBerry/i.test(userAgent);
  return { props: { isH5 } };
}
const NewHome = ({ isH5 }) => {
  return <PageContainer pc={<Home />} h5={<HomeMobile />} isH5={isH5} />;
  // return <Home />;
};

export default NewHome;
