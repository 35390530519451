import React, { useState } from 'react';
import { Icon } from "@iconify/react";
import useLogin from "@/hooks/useLogin";
import styles from './index.module.scss';

const ListCard = ({ sourceData, typePage, openId, selectedProducts, onPress, toggleSelection, collectChange, goDetailPage, addChange, viewChange }) => {
  const isOpen = openId === sourceData.productId || openId === sourceData.id ? true : false;
  const img = typePage === 'home' || typePage === 'collection' ? sourceData.alyImage ? sourceData.alyImage : sourceData.image : sourceData?.main_images?.[0]?.url;
  const isSelectedProducts = selectedProducts?.includes(sourceData?.productId);
  // console.log('~2222222', sourceData, selectedProducts, isSelectedProducts);
  const originalPrice = typePage === 'showcases'
    && (sourceData?.price.original_price.maximum_amount === sourceData?.price.original_price.minimum_amount
      ? `$${sourceData?.price.original_price.maximum_amount}`
      : `$${sourceData?.price.original_price.minimum_amount}-$${sourceData?.price.original_price.maximum_amount}`);
  const sellerPrice = typePage === 'showcases'
    && (sourceData?.price.seller_discount_price.maximum_amount === sourceData?.price.seller_discount_price.minimum_amount
      ? `$${sourceData?.price.seller_discount_price.maximum_amount}`
      : `$${sourceData?.price.seller_discount_price.minimum_amount}-$${sourceData?.price.seller_discount_price.maximum_amount}`);
  const tiktokPrice = typePage === 'showcases'
    && (sourceData?.price.platform_discount_price.maximum_amount === sourceData?.price.platform_discount_price.minimum_amount
      ? `$${sourceData?.price.platform_discount_price.maximum_amount}` :
      `$${sourceData?.price.platform_discount_price.minimum_amount}-$${sourceData?.price.platform_discount_price.maximum_amount}`);
  const { needLogin } = useLogin();
  const [collected, setCollected] = useState(sourceData.collect);
  const infoList = typePage === 'home' ? [
    { title: "VS.Open Collab", value: `${sourceData?.openRate}%` ?? "-" },
    { title: "Price", value: sourceData?.price ?? "-" },
    { title: "Total Sold", value: sourceData?.soldNum ?? "-" },
    { title: "Total Sales", value: `$${sourceData?.soldAmount}` ?? "-" }
  ] : typePage === 'collection' ? [
    { title: "VS.Open Collab", value: `${sourceData?.openRate}%` ?? "-" },
    { title: "Price", value: sourceData?.price ?? "-" }
  ] : [
    { title: "Original Price", value: originalPrice ?? "-" },
    { title: "Seller Price", value: sellerPrice ?? "-" },
    { title: "Tiktok Price", value: tiktokPrice ?? "-" }
  ];

  const toggleCollect = (e) => {
    if (!needLogin) {
      setCollected(!collected);
    }
    collectChange(e, sourceData, collected);
  };

  return (
    <div className={`${styles.listCard} ${isOpen ? styles.listCardBorder : ''}`}>
      <div className={styles.topWrap} onClick={() => { goDetailPage(sourceData); }}>
        {typePage === 'collection' && <div onClick={(e) => { toggleSelection(e, sourceData); }} className={styles.selectBox}>
          {!isSelectedProducts ? (
            <Icon icon='solar:check-circle-line-duotone' className={styles.unSelect} />
          ) : (
            <Icon icon='solar:check-circle-bold-duotone' className={styles.select} />
          )}
        </div>}
        <div className={styles.imageBox}>
          <img className={styles.imgge} src={img} />
        </div>
        <div className={styles.content}>
          <div className={styles.contentTitle}>{sourceData.title}</div>
          {typePage === 'home' || typePage === 'collection' ? <>
            <div className={styles.contentEarn}>
              <span className={styles.earnTitle}>${sourceData?.earnPer ?? "-"}</span>
              <span className={styles.earnText}>Earn per sale</span>
            </div>
            <div className={styles.contentCommission}>
              <span className={styles.earnTitle}>{`${sourceData?.finishRate}%` ?? "-"}</span>
              <span className={styles.earnText}>Commission</span></div>
          </> : <>
            <div className={styles.contentEarn}>
              <span className={styles.earnTitle}>{`${sourceData?.commission?.rate / 100}%` ?? "-"}</span>
              <span className={styles.earnText}>Commission Rate</span>
            </div>
            <div className={styles.contentCommission}>
              <span className={`${styles.status} ${sourceData?.status.inventory_status === 'Sold Out' ? styles.statusBad : ''}`}>{sourceData?.status.inventory_status}</span>
              <span className={styles.earnText}>Inventory Status</span></div>
          </>}
        </div>
        <div className={styles.group}>
          <div onClick={(e) => { onPress(e, sourceData); }}>
            {isOpen ? <Icon icon='lucide:chevron-up' className={styles.open} /> : <Icon icon='lucide:chevron-down' className={styles.open} />}
          </div>
          {(typePage === 'home' || typePage === 'collection') &&
            <Icon icon='solar:star-bold-duotone'
              className={`${styles.collect} ${collected ? styles.collectActive : ''}`}
              onClick={(e) => { toggleCollect(e, sourceData); }} />}
          {typePage === 'home' && <div className={styles.add} onClick={(e) => { addChange(e, sourceData); }}><div className={styles.text}>+Add</div></div>
          }
          {typePage === 'showcases' && <div className={`${styles.view} ${!sourceData?.isPlatformProduct ? styles.viewDiff : ''}`} onClick={(e) => { viewChange(e, sourceData); }}><div className={styles.text}>View</div></div>}
        </div>
      </div>
      {isOpen && (
        <div className={styles.info}>
          <div className={styles.line} />
          {infoList?.map(({ title, value }, index) => (
            <div key={index} className={styles.infoGroup}>
              <span className={styles.infoTitle}>{title}</span>
              <span className={styles.infoText}>{value}</span>
            </div>
          ))}
        </div>
      )}

    </div>
  );
};
export default ListCard;