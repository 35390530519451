// ================= Home Part ==================== //
export const INCREMENT = 'INCREMENT';
export const DECREMENT = 'DECREMENT';
export const RESET = 'RESET';
export const ADD_TO_NUMBER = 'ADD_TO_NUMBER';

// ================= User Part ==================== //

export const FETCH_USER_LIST = 'FETCH_USER_LIST';
export const FETCH_USER_LIST_FAIL = 'FETCH_USER_LIST_FAIL';
export const FETCH_USER_LIST_SUCCESS = 'FETCH_USER_LIST_SUCCESS';


export const SET_WALLET_INFO = 'SET_WALLET_INFO';
export const SET_USER_INFO = 'SET_USER_INFO';
export const SET_USER_PERMISSION = 'SET_USER_PERMISSION';


// ================= mobile ==================== //

export const FETCH_PRODUCT_LIST = 'FETCH_PRODUCT_LIST';
export const FETCH_PRODUCT_LIST_SUCCESS = 'FETCH_PRODUCT_LIST_SUCCESS';
export const FETCH_PRODUCT_LIST_FAILURE = 'FETCH_PRODUCT_LIST_FAILURE';
export const SET_SEARCH = 'SET_SEARCH';
export const FETCH_CATEGORY_FIRST = 'FETCH_CATEGORY_FIRST';
export const FETCH_CATEGORY_FIRST_SUCCESS = 'FETCH_CATEGORY_FIRST_SUCCESS';
export const FETCH_CATEGORY_FIRST_FAILURE = 'FETCH_CATEGORY_FIRST_FAILURE';
export const FETCH_CATEGORY_SECOND = 'FETCH_CATEGORY_SECOND';
export const FETCH_CATEGORY_SECOND_SUCCESS = 'FETCH_CATEGORY_SECOND_SUCCESS';
export const FETCH_CATEGORY_SECOND_FAILURE = 'FETCH_CATEGORY_SECOND_FAILURE';
export const FETCH_PRODUCT_INFO = 'FETCH_PRODUCT_INFO';
export const FETCH_PRODUCT_INFO_SUCCESS = 'FETCH_PRODUCT_INFO_SUCCESS';
export const FETCH_PRODUCT_INFO_FAILURE = 'FETCH_PRODUCT_INFO_FAILURE';
export const FETCH_ADD_COLLECT = 'FETCH_ADD_COLLECT';
export const FETCH_ADD_COLLECT_SUCCESS = 'FETCH_ADD_COLLECT_SUCCESS';
export const FETCH_ADD_COLLECT_FAILURE = 'FETCH_ADD_COLLECT_FAILURE';
export const FETCH_DELETE_COLLECT = 'FETCH_DELETE_COLLECT';
export const FETCH_DELETE_COLLECT_SUCCESS = 'FETCH_DELETE_COLLECT_SUCCESS';
export const FETCH_DELETE_COLLECT_FAILURE = 'FETCH_DELETE_COLLECT_FAILURE';
export const FETCH_USER_GMV = 'FETCH_USER_GMV';
export const FETCH_USER_GMV_SUCCESS = 'FETCH_USER_GMV_SUCCESS';
export const FETCH_USER_GMV_FAILURE = 'FETCH_USER_GMV_FAILURE';
export const FETCH_SHOWCASES_LIST = 'FETCH_SHOWCASES_LIST';
export const FETCH_SHOWCASES_LIST_SUCCESS = 'FETCH_SHOWCASES_LIST_SUCCESS';
export const FETCH_SHOWCASES_LIST_FAILURE = 'FETCH_SHOWCASES_LIST_FAILURE';
export const FETCH_LIKE_PRODUCT_LIST = 'FETCH_LIKE_PRODUCT_LIST';
export const FETCH_LIKE_PRODUCT_LIST_SUCCESS = 'FETCH_LIKE_PRODUCT_LIST_SUCCESS';
export const FETCH_LIKE_PRODUCT_LIST_FAILURE = 'FETCH_LIKE_PRODUCT_LIST_FAILURE';

